  .abtn {
    background: linear-gradient(rgb(255, 255, 255) 3.35%, rgba(255, 255, 255, 0.73) 100%);
    display: flex;
    position: relative;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    padding: 6px 20px;
    color: rgb(45, 50, 59);
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }

  .wrapper h1 {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    white-space: nowrap;
    line-height: 1;
  }

  .wrapper h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    text-align: center;
  }

  .wrapper fieldset {
    display: flex;
    background: rgb(4, 113, 255);
    padding: 4rem;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
  }

  .wrapper section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome > div:first-of-type {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
    margin-left: 6rem;
    margin-right: 6rem;
    max-width: 72rem;
  }

  .welcome > div:first-of-type > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 2rem;
    flex: 1.5;
  }

  .welcomeimg {
    max-width: 28rem;
    flex: 1;
  }

  .grey {
  color: hsl(0, 0%, 50%);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  }

  .logos {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem 0;
  overflow: hidden;
  }

  .logos::before,
  .logos::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  z-index: 1;
  pointer-events: none;
  }

  .logos::before {
  left: 0;
  background: linear-gradient(to right, var(--bg-color) 0%, hsla(var(--bg-color-alpha), 0) 100%);
  }

  .logos::after {
  right: 0;
  background: linear-gradient(to right, hsla(var(--bg-color-alpha), 0) 0%, var(--bg-color) 100%);
  }

  .track {
    animation: scroll 20s linear infinite;
    position: absolute;
    display: flex;
    left: 0;
  }

  .track > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
  }

  .track svg {
    filter: saturate(0) contrast(0) opacity(.6);
    transition: filter .25s ease;
    cursor: pointer;
  }

  .track svg:first-of-type {
    margin-left: 40px;
  }

  .track .navy {
    color: var(--navy);
  }

  .track svg:hover {
  filter: saturate(1) contrast(1) opacity(1);
  }

  .track:hover {
  animation-play-state: paused;
  }

  .card a {
  box-shadow: rgba(17, 17, 26, 0.3) 0px 0px 16px;
  border: 1px solid var(--outline);
  transition: border .3s ease-in-out;
  }

  .card a img {
  transition: transform .2s ease-in-out;
  }

  .card a:hover img, .card a:focus-visible img {
    transform: scale(1.1);
  }

  .payment {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 24px;
    font-weight: 500;
  }

  .payment > div {
    display: flex;
    gap: 24px;
  }

  .payment p {
    background: hsla(var(--color-alpha), .17);
    border-radius: 8px;
    padding: 6px 12px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease-in-out;
  }

  .articlesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    background: none;
    padding: 0px 2rem;
    width: 100%;
  }

  .articlesTweet {
    position: relative;
    flex: 1 1 300px;
    max-width: 350px;
    min-width: 280px;
    min-height: fit-content;
    height: 500px;
    box-sizing: border-box;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  
  .articlesTweet:hover {
    transform: translateY(-5px);
  }

  .skeletonContainer {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 16px;
  }

  .tweetSkeleton {
    border: 0.05rem solid var(--skeleton1);
    border-radius: 15px;
    height: 100%;
    width: 100%;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
  .tweetSkeleton .img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: var(--skeleton1);
    animation: tweet-skeleton 1.3s linear infinite alternate;
  }
  .tweetSkeleton .content1,
  .tweetSkeleton .content2 {
    height: 25%;
    margin-top: 1rem;
  }
  .tweetSkeleton .line {
    height: 15%;
    margin: 0.5rem 0;
    width: 100%;
    border-radius: 0.3rem;
    background-color: var(--skeleton1);
    animation: tweet-skeleton 1.3s linear infinite alternate;
  }
  .tweetSkeleton .line:last-child {
    width: 75%;
  }

  @keyframes tweet-skeleton {
    0%{
      background-color: var(--skeleton1);
    }
    100%{
        background-color: var(--skeleton2);
    }
  }

  .faqheading {
    margin: 3rem 0px;
  }

  .faqwrapper {
    width: 100%;
    max-width: 700px;
    padding: 0px 2rem;
    margin-bottom: 4rem;
  }

  .faqwrapper > div {
    background: var(--container);
    border-radius: 24px;
    transition: background-color .3s ease-in-out;
  }
  
  .faqwrapper .categorieswrapper {
    width: 100%;
    overflow: scroll;
  }

  .faqwrapper .categorieswrapper::-webkit-scrollbar { 
    display: none;
  }

  .faqwrapper .categories {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    min-width: 100%;
    width: fit-content;
    white-space: nowrap;
    border-bottom: 2px solid hsla(var(--color-alpha), .2);
    transition: border .3s ease-in-out;
  }

  .faqwrapper .category {
    flex: 1;
    text-align: center;
    padding: 12px 24px;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .faqwrapper .question {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    cursor: pointer;
    word-break: break-word;
  }

  .faqwrapper .answer {
    overflow: hidden;
    font-size: 16px;
  }

  .faqwrapper .answer p {
    margin: 12px 24px;
    font-weight: 400;
    word-break: break-word;
  }

  .faqwrapper .faq {
    padding: 12px 0px;
  }

  .faqwrapper .category .selection {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: hsla(204, 100%, 56%, 0);
    transition: background-color .15s ease-out;
  }

  .faqwrapper .category .selection.selected {
    background: hsla(204, 100%, 56%, 1);
  }

  @keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
  }

  @keyframes animateDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
  }

  @media screen and (max-width: 1024px) {
    .wrapper h1 {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 800px) {
    .welcome > div:first-of-type {
      flex-direction: column;
      margin-top: 2rem;
      margin-left: 3rem;
      margin-right: 3rem;
    }
    .wrapper fieldset {
      padding: 2rem 1rem;
      gap: 1rem;
    }
    .wrapper fieldset:first-of-type p {
      font-size: 24px;
    }
    .wrapper fieldset a {
      font-size: 14px;
      padding: 6px 10px;
    }
    .wrapper fieldset div {
      gap: 0.75rem;
    }
    .wrapper fieldset h2 {
      font-size: 28px;
    }
    .payment {
      flex-wrap: wrap;
      gap: 16px;
    }
    .articlesTtweet {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
    .articlesContainer {
      gap: 2rem;
    }
  }