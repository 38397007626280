.panelwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom {
    padding: 8px;
    border-radius: 12px;
    box-shadow: -3px 6px 20px hsla(0, 0%, 0%, .08),
                -3px 6px 40px hsla(0, 0%, 0%, .15);
    background: var(--container);
    gap: 10px;
    width: fit-content;
    max-width: 320px;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.Type {
    padding: 4px;
    display: flex;
    gap: 6px;
    width: 100%;
}

.Currency {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 4px;
}

.Currency > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.optwrapper {
    box-shadow: -1px 2px 8px hsla(0, 0%, 0%, .1),
                inset 0px 0px 12px hsla(0, 0%, 0%, 0);
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out, outline .15s ease-in-out;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    outline: 1px solid hsla(0, 0%, 0%, 0);
    user-select: none;
}

.option {
    background: var(--trans);
    padding: 14px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out, outline .15s ease-in-out;
    cursor: pointer;
    text-align: center;
}

.option > span {
    transform: translateY(1px);
}

.selected {
    box-shadow: -1px 2px 8px hsla(0, 0%, 0%, 0),
                inset 0px 0px 12px hsla(0, 0%, 0%, .2);
    background: var(--trans-active);
}

.optwrapper:focus-within, .option:hover, .optwrapper:hover {
    outline: 1px solid var(--outline);
    background: var(--trans-hover);
}

.optwrapper:hover > .option, .option:focus-visible {
    background: var(--trans-hover);
}

.Offer {
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    justify-content: space-evenly;
    align-items: center;
    box-shadow: -1px 2px 12px hsla(0, 0%, 0%, .1);
    padding: 0px 0.7em;
    margin: 4px 0px;
    height: 52px;
    border-radius: 8px;
    gap: 10px;
    position: relative;
    background: var(--trans);
    transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out, outline .3s ease-in-out;
}

.Offer > label {
    text-align: center;
    color: #8b8b8b;
    position: absolute;
    left: 20px;
}

.Offer > input {
    padding-right: 8px;
    border: none;
    font-size: 18px;
    height: 70%;
    width: 65%;
    text-align: right;
    border-radius: 14px;
    background: none;
    outline: 1px solid hsla(0, 0%, 100%, 0);
    transition: background-color .3s ease-in-out, box-shadow .2s ease-in-out, outline .2s ease-in-out;
    box-shadow: inset 0px 0px 20px hsla(0, 0%, 0%, 0);
}

.Offer > input:focus-visible {
    outline: 1px solid var(--outline);
    box-shadow: inset 0px 0px 12px hsla(0, 0%, 0%, .15);
}

.fiat {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 200;
    position: relative;
    cursor: pointer;
    border-radius: 12px;
    outline-offset: 4px;
}

.fiat > span {
    font-weight: 300;
}

.list {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: var(--trans);
    gap: 2px;
    width: 110%;
    border-radius: 0px 0px 8px 8px;
    max-height: 0;
    padding: 0;
    top: 39px;
    z-index: 6;
    transition: .3s ease-in-out;
    visibility: hidden;
}

.fiat .active {
    max-height: 700px;
    padding-bottom: .5em;
    height: auto;
    border: 1px solid var(--color);
    border-top: 0px;
    visibility: visible;
}

.list > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: .25em 0;
    transition: .2s ease-in-out;
}

.list > div:hover, .list > div:focus-visible {
    box-shadow: inset 0 0 100px 100px hsla(var(--color-alpha), .1);
    outline: none;
}

.buywrapper {
    width: calc(100% - 10px);
    max-width: 250px;
    margin: 16px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Buy {
    position: relative;
    z-index: 1;
    padding: 6px 12px;
    background: transparent;
    text-transform: uppercase;
    outline: none;
    color: var(--color);
    transition: .25s ease-in-out, color .3s ease-out;
    user-select: none;
    font-size: 16px;
    display: flex;
    gap: 6px;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 54px;
    font-weight: bold;
    border-radius: 16px;
  }
  .Buy::before {
    position: absolute;
    border-radius: 12px;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 1;
    transition: .3s ease-in-out, box-shadow .15s ease-in-out, color .3s;
    background: linear-gradient(135deg, hsl(269, 85%, 43%) 0%, hsl(218, 97%, 57%) 80%);
  }
  
  .Buy::after {
    position: absolute;
    border-radius: 10px;
    left: 2px;
    top: 2px;
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    z-index: -1;
    opacity: 1;
    transition: .3s ease-in-out, box-shadow .15s ease-in-out, color 0s;
    background: var(--buy-background);
  }
  
  .Buy:hover::after, .Buy:focus-visible::after {
    opacity: 0;
  }
  
  .Buy:hover, .Buy:focus-visible {
    outline: none;
    color: hsl(0, 0%, 100%);
  }
  
  .Buy:active::before{
    box-shadow: inset 0px 0px 12px hsla(0, 0%, 0%, .5);
  }

.disabled {
    color: hsl(217, 7%, 51%);
    background: var(--disabled);
    cursor: not-allowed;
}

.disabled.Buy::before {
    background: var(--disabled-outline);
}

.disabled.Buy::after {
    background: var(--disabled);
}

.disabled:hover::after, .disabled:focus-visible::after {
    opacity: 1;
}

.disabled:hover, .disabled:focus-visible {
    color: hsl(217, 7%, 51%);
    background: var(--disabled);
}